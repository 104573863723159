import React, { useState } from "react";
import { Notes } from "./Notes";
import { NotesForm } from "./NotesForm";
import { v4 as uuidv4 } from "uuid";
import { EditNotesForm } from "./EditNotesForm";

export const NotesWrapper = () => {
    const [notess, setNotes] = useState([]);

    const addNotes = (notes) => {
        setNotes([
            ...notess,
            { id: uuidv4(), task: notes, completed: false, isEditing: false},
        ]);
    }

    const deleteNotes = (id) => setNotes(notess.filter((notes) => notes.id !== id));
    
    const toggleComplete = (id) => {
        setNotes(
            notess.map((notes) => 
            notes.id === id ? { ...notes, completed: !notes.completed } : notes
            
          )
        );
    }

    const editNotes = (id) => {
        setNotes(
            notess.map((notes) =>
            notes.id === id ? { ...notes, isEditing: !notes.isEditing } : notes
            )
        );
    }

    const editTask = (task, id) => {
        setNotes(
            notess.map((notes) =>
                notes.id === id ? { ...notes, task, isEditing: !notes.isEditing } : notes
            )
        );
    };

    return (
        <div className="NotesWrapper">
            <h1> SCTEE-G DAILY STUDY NOTES ! </h1>
            <NotesForm addNotes={addNotes}/>
            {notess.map((notes) =>
                notes.isEditing ? (
                    <EditNotesForm editNotes={editTask} task={notes}/>
                ) : (
                    <Notes
                    key={notes.id}
                    task={notes}
                    deleteNotes={deleteNotes}
                    editNotes={editNotes}
                    toggleComplete={toggleComplete}
                    />
                )
            )}
        </div>
    );
};